<script setup lang="ts">
import type { ReviewData } from '~/composables/reviews'

const props = defineProps<{
  review: ReviewData
}>()

const { t, d } = useI18n()

const showOriginal = ref(false)

const displayedReviewBody = computed(() => showOriginal.value ? props.review.originalBody : props.review.body)

function toggleShowOriginal() {
  showOriginal.value = !showOriginal.value
}
</script>

<template>
  <div>
    <div class="flex-none">
      <NAvatar icon="i-heroicons:user-20-solid" alt="" class="h-10 w-10 rounded-full bg-slate-100" />
    </div>

    <div class="flex-1">
      <div class="flex">
        <h3 class="mr2 text-slate-900 fw600">
          {{ review.authorName }}
        </h3>
        <p>
          <time :datetime="review.createdAt">{{ d(review.createdAt) }}</time>
        </p>
      </div>

      <div class="mt2 flex items-center">
        <Rating :rating="review.rating" class="-ml0.5" />
      </div>

      <p class="sr-only">
        {{ t('product.rating.x_out_of_y_stars.label', { x: review.rating, y: 5 }) }}
      </p>

      <div class="prose-sm mb2 mt4 max-w-none text-slate-500 prose">
        {{ displayedReviewBody }}
      </div>

      <div>
        <NLink class="cursor-pointer" @click="toggleShowOriginal">
          <NIcon icon="i-heroicons:globe-europe-africa-20-solid" />
          {{ showOriginal ? t('general.translate.label') : t('general.translate.show_original') }}
        </NLink>
      </div>
    </div>
  </div>
</template>
