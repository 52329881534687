<script setup lang="ts">
const props = withDefaults(defineProps<{
  columns?: number
  multiple?: boolean
  open?: string | string[]
}>(), {
  columns: 4,
  multiple: true,
  open: [] as any,
})

const emit = defineEmits<{
  (event: 'change', args: string[]): void
}>()

const { t } = useI18n()

const companyName = useRuntimeConfig().public.company.name

const isOpen = ref<string[]>([])
const items = ref<string[]>([])

function toggle(payload: string) {
  if (!props.multiple)
    isOpen.value = [payload]
  else if (isOpen.value.includes(payload))
    isOpen.value = isOpen.value.filter(item => item !== payload)
  else
    isOpen.value = [...isOpen.value, payload]

  emit('change', isOpen.value)
}

const isColumnOpen = (title: string) => isOpen.value.includes(title)
const addFooterItem = (title: string) => items.value.push(title)

const socials = useSocials()

provide('footerContextKey', { isColumnOpen, toggle, addFooterItem, items })
</script>

<template>
  <footer aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">
      Footer
    </h2>

    <div class="pb8 xl:grid xl:grid-cols-4 xl:gap-8">
      <div
        class="flex flex-1 flex-col md:grid md:gap-8"
        :style="{ 'grid-template-columns': `repeat(${columns}, minmax(0, 1fr))`, 'grid-column': `span ${columns} / span ${columns}` }"
      >
        <slot name="columns" />
      </div>
    </div>

    <slot v-if="$slots.subfooter" name="subfooter" />

    <div class="mt8 border-t n-border-200 pt8 md:flex md:items-center md:justify-between">
      <div class="flex md:order-2 space-x-6">
        <div v-for="social in socials" :key="social.label">
          <NLink :href="social.href" class="text-context" n="slate4 hover:slate5">
            <span class="sr-only" v-text="social.label" />
            <NIcon :icon="social.icon" n="xl" />
          </NLink>
        </div>
      </div>

      <p class="mt-8 text-base text-slate-400 md:order-1 md:mt-0">
        &copy; {{ new Date().getFullYear() }} {{ companyName }}, {{ t('general.all_rights_reserved.label') }}.
      </p>
    </div>
  </footer>
</template>
