<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <div class="relative mx-auto mt8 max-w-7xl w-full flex overflow-hidden rd-xl bg-slate-200">
    <div class="relative flex flex-1 justify-center">
      <img
        src="~/assets/screenshots/phone-sm.png"
        class="absolute left-1/2 top-1/2 h-auto max-w-full scale-75 transform -translate-x-1/2 -translate-y-1/2"
        alt=""
        loading="lazy"
      >
    </div>

    <div class="flex-1 self-center py12">
      <div class="pr4">
        <h3 class="text-2xl fw600">
          {{ t('footer.app.title') }}
        </h3>
        <p class="mb4 text-base text-slate-700">
          {{ t('footer.app.subtitle') }}
        </p>
      </div>

      <div class="flex flex-wrap">
        <NLogo :logo="`app-store-badge-${$i18n.locale}`" n="4xl" class="mb2 mr2" />
        <NLogo :logo="`google-play-badge-${$i18n.locale}`" n="4xl" class="mb2" />
      </div>
    </div>
  </div>
</template>
