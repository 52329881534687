<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import type { Ref } from 'vue'
import { useReviewsState } from '~/composables/reviews'

const LEGAL_PAGES_PATH_PREFIX = '/legal'

const { t, locale, locales } = useI18n()

const localeObject = computed(() => (locales as Ref<LocaleObject[]>).value.find(l => l.code === locale.value))

const reviewsState = useReviewsState()

const [
  { data: reviews },
  { data: navigation },
] = await Promise.all([
  useAsyncData('reviews', () => $fetch('/api/reviews', {
    query: { languageCode: locale.value, currencyCode: localeObject.value?.currency },
    onRequest(ctx) {
      ctx.options.headers = { ...ctx.options.headers, 'Content-Language': locale.value, 'Content-Currency': localeObject.value?.currency }
    },
  }).then((result) => {
    reviewsState.value = result.data

    return result.data
  })),
  useAsyncData('navigation', () => fetchContentNavigation({ where: [{ _locale: locale.value }] }), {
    transform: (data) => {
      let nav = data?.map(nav => ({ ...nav, title: t(nav.title) })).filter(nav => !nav._path.includes(LEGAL_PAGES_PATH_PREFIX))

      let usefulLinksNavigation = nav.find(nav => nav._path === '/useful-links')

      if (usefulLinksNavigation) {
        usefulLinksNavigation = { ...usefulLinksNavigation }
        const children = usefulLinksNavigation.children ?? []

        if (!children.some(child => child._path === '/account'))
          children.unshift({ _path: '/account', title: t('page.account.title') })

        usefulLinksNavigation.children = children
        nav = nav.map(item => item._path === usefulLinksNavigation?._path ? usefulLinksNavigation : item)
      }

      const legal = data.find(nav => nav._path.includes(LEGAL_PAGES_PATH_PREFIX))?.children ?? []

      return { footer: nav, legal }
    },
  }),
])
</script>

<template>
  <!-- Pre-footer -->
  <div class="mx-auto max-w-7xl px4 py6 lg:px8 lg:py12 sm:px6">
    <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
      <div>
        <ContentQuery v-slot="{ data }" path="footer" find="one" :locale="locale">
          <ContentRenderer :value="data" />
        </ContentQuery>
      </div>

      <div>
        <h3 class="text-base fw600 n-text-heading" v-text="t('footer.shopping_with', [$config.public.storefront.name])" />
        <div class="mt3">
          <div
            v-for="(benefit, bIndex) in [
              { icon: 'i-mdi:gift-outline', text: t('policies.gift_with_every_purchase') },
              { icon: 'i-mdi:truck-outline', text: t('policies.free_shipping') },
              { icon: 'i-mdi:shield-check-outline', text: t('policies.customer_satisfaction_guarantee') },
              { icon: 'i-mdi:package-variant-closed', text: t('product.shipping_guarantee.label') },
              { icon: 'i-mdi:shield-lock-outline', text: t('payment_acceptance.secure_payment') },
              { icon: 'i-mdi:check-circle-outline', text: t('policies.shop_with_confidence') },
              { icon: 'i-mdi:credit-card-refund-outline', text: t('policies.free_exchanges') },
              { icon: 'i-mdi:headset', text: t('policies.satisfaction_guarantee.customer_support.title') },
              { icon: 'i-mdi:leaf-circle-outline', text: t('policies.eco_friendly_packaging') },
            ]"
            :key="bIndex"
            class="mb4 flex items-center"
          >
            <NIcon class="text-context" :icon="benefit.icon" n="xl" />
            <p class="ml2 text-context" n="base slate5 dark:slate7" v-text="benefit.text" />
          </div>
        </div>
      </div>

      <div>
        <h3 class="text-base fw600 n-text-heading" v-text="t('footer.customer_reviews')" />
        <div class="mt3">
          <template v-if="reviews?.length">
            <ProductReview
              v-for="(review, rIndex) in reviews"
              :key="`r-${rIndex}`"
              class="flex pb4 text-sm text-slate-500 space-x-4"
              :class="{ 'pt4 border-t n-border-base': rIndex !== 0 }"
              :review="review"
            />
          </template>
        </div>
      </div>
    </div>
  </div>

  <Footer :columns="4" class="mx-auto max-w-7xl md:n-border-200 md:border-t px4 py6 lg:px8 lg:py12 sm:px6">
    <template #columns>
      <FooterColumn v-for="nav in navigation?.footer" :key="nav.title" :title="nav.title">
        <li v-for="item in nav.children" :key="item.title">
          <NuxtLinkLocale :to="item._path" class="text-base text-slate-500 hover:text-slate-900">
            {{ item.title }}
          </NuxtLinkLocale>
        </li>
      </FooterColumn>
    </template>

    <template #subfooter>
      <div class="pt4 xl:mt0">
        <div class="mx-auto max-w-7xl overflow-hidden md:n-border-200 md:border-t px6 pb6 pt6 lg:px8">
          <div class="grid grid-cols-2 columns-2" aria-label="Footer">
            <div>
              <h3 class="text-sm text-slate-900 font-semibold leading-6">
                {{ t('footer.accepted_payments.title') }}
              </h3>
              <div class="col-span-1 flex flex-wrap items-center justify-start gap-2">
                <NLogo logo="payments/visa" n="lg" class="mr4 mt2 text-visa dark:text-white" />
                <NLogo logo="payments/mastercard" n="2xl" class="mr4 mt2" />
                <NLogo logo="payments/amex" n="2xl" class="mr4 mt2 text-amex" />
                <NLogo logo="payments/discover-2" n="3xl" class="mr4 mt2" />
                <NLogo logo="payments/dinersclub-square" n="3xl" class="mr4 mt2" />
                <NLogo logo="payments/maestro" n="2xl" class="mr4 mt2" />
                <NLogo logo="payments/jcb" n="3xl" class="mr4 mt2" />
                <NLogo logo="payments/cb" n="3xl" class="mr4 mt2" />
                <NLogo logo="payments/apple-pay" n="3xl" class="mr4 mt2" />
                <NLogo logo="payments/google-pay" n="3xl" class="mr4 mt2" />
              </div>
            </div>

            <div>
              <h3 class="text-sm text-slate-900 font-semibold leading-6">
                {{ t('footer.security_certification.title') }}
              </h3>
              <div class="col-span-1 flex flex-wrap items-center justify-start gap-2">
                <NLogo logo="certificates/visa-secure" n="3xl" class="mr4 mt2 c-visa" />
                <NLogo logo="certificates/mastercard-id-check" n="2xl" class="mr4 mt2" />
                <NLogo logo="certificates/amex-safekey" n="2xl" class="mr4 mt2 c-amex" />
                <NLogo logo="certificates/j-secure" n="2xl" class="mr4 mt2" />
                <NLogo logo="certificates/discover-protectbuy" n="3xl" class="mr4 mt2" />
                <NLogo logo="certificates/pci-dss-compliant" n="2xl" class="mr4 mt2" />
                <NLogo logo="certificates/digicert-secured" n="3xl" class="mr4 mt2" />
                <NLogo logo="certificates/trusted-site" n="3xl" class="mr4 mt2" />
              </div>
            </div>
          </div>
        </div>

        <div class="mx-auto max-w-7xl overflow-hidden border-t n-border-200 px6 pt6 lg:px8">
          <nav class="columns-2 -mb6 sm:flex sm:flex-wrap sm:justify-around sm:space-x-6" aria-label="Footer">
            <template v-if="navigation?.legal?.length">
              <div v-for="item in navigation?.legal" :key="item._path" class="pb-6">
                <NuxtLinkLocale :to="item._path" class="text-sm text-slate-500 leading-6 hover:text-slate-900">
                  {{ item.title }}
                </NuxtLinkLocale>
              </div>
            </template>
            <template v-else>
              <div v-for="i in 10" :key="i" class="pb-6">
                <NSkeleton class="w-24" :style="{ width: `${100 + (i * i + i) % 7 * 20}px` }" pulse />
              </div>
            </template>
          </nav>
        </div>
      </div>
    </template>
  </Footer>
</template>
