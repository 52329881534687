<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

const { locale, t } = useI18n()
const localePath = useLocalePath()
const { ecommerce } = useAnalytics()
const router = useRouter()
const route = useRoute()
const { navigation: accountNav } = useAccount()

const { activeOrder } = useActiveOrder()
const { rootCollections } = useCollections()
const { el: headerRef, isHeaderVisible } = useUiHeader()

const drawers = useDrawers()

const loggedIn = computed(() => useAuth().loggedIn.value === true)

const searchQuery = useRouteQuery('q', '')
const searchQueryInput = ref(searchQuery.value)

const isSearchPage = computed(() => ((route?.name as string) ?? '').includes('search'))
const isProductPage = computed(() => ((route?.name as string) ?? '').includes('products-id-slug'))
const isCheckoutPage = computed(() => ((route?.name as string) ?? '').includes('checkout'))

const showMessenger = ref(false)
const messengerVisible = computed(() => !(isCheckoutPage.value || isProductPage.value))
function openMessenger() {
  showMessenger.value = true
  useSupportAgent().maximizeChat()
}

async function handleCartClick() {
  drawers.value.cart.open = true
  ecommerce.viewCart()
}

function handleNavigationClick() {
  drawers.value.navigation.open = true
}

function submitSearch(event: Event) {
  event.preventDefault()
  router.push({
    path: localePath('/search'),
    query: { q: searchQueryInput.value },
  })
}

watch(isSearchPage, (isSearchPage) => {
  if (!isSearchPage)
    searchQueryInput.value = ''
})

const days = computed(() => {
  const { 0: monday, 4: friday } = getWeekdays(locale.value, 'short')
  return `${monday} - ${friday}`
})
const hours = computed(() => {
  const { 6: six, 18: eighteen } = getHours(locale.value)
  return `${six} - ${eighteen}`
})

// account menu
const accountMenuTrigger = ref<HTMLElement | null>(null)

// https://github.com/tailwindlabs/headlessui/blob/f66f4926c489fc15289d528294c23a3dc2aee7b1/packages/%40headlessui-vue/src/components/menu/menu.ts#L131
const menuApi = ref<any>(null)
onMounted(() => {
  setTimeout(() => {
    // @ts-expect-error internals
    const menuProvides = accountMenuTrigger.value?.$.provides
    if (!menuProvides)
      return

    const menuProvidesSymbols = Object.getOwnPropertySymbols(menuProvides)
    menuApi.value = menuProvidesSymbols.length && menuProvides[menuProvidesSymbols[0]]
  }, 200)
})

let openTimeout: NodeJS.Timeout | null = null
let closeTimeout: NodeJS.Timeout | null = null
function onMouseOver() {
  if (!loggedIn.value || !menuApi.value)
    return

  // cancel programmed closing
  if (closeTimeout) {
    clearTimeout(closeTimeout)
    closeTimeout = null
  }
  // dropdown already opens
  if (menuApi.value.menuState === 0)
    return

  openTimeout = openTimeout || setTimeout(() => {
    menuApi.value.openMenu && menuApi.value.openMenu()
    openTimeout = null
  }, 0)
}

function onMouseLeave() {
  if (!loggedIn.value || !menuApi.value)
    return

  // cancel programmed opening
  if (openTimeout) {
    clearTimeout(openTimeout)
    openTimeout = null
  }
  // dropdown already closed
  if (menuApi.value.menuState === 1)
    return

  closeTimeout = closeTimeout || setTimeout(() => {
    menuApi.value.closeMenu && menuApi.value.closeMenu()
    closeTimeout = null
  }, 100)
}

onUnmounted(() => {
  if (openTimeout)
    clearTimeout(openTimeout)

  if (closeTimeout)
    clearTimeout(closeTimeout)
})
</script>

<template>
  <!--      <TopBar class="bg-[conic-gradient(at_top_right,_var(&#45;&#45;tw-gradient-stops))] from-rose-500 via-red-500 to-orange-500 fw600 text-white"> -->
  <div class="n-top-bar relative z50 block hidden bg-slate-900 text-white fw600 lg:block">
    <div class="mx-auto h-10 max-w-7xl flex items-center justify-between px-4 lg:px8 sm:px6">
      <span class="inline-flex items-center justify-center fw600 uppercase" n="sm">
        <NIcon icon="i-heroicons:gift" class="n-button-icon" /> {{ t('incentives.gift_with_every_purchase.title') }}
      </span>

      <span class="inline-flex items-center justify-center fw600 uppercase" n="sm">
        <NIcon icon="i-heroicons:clock" class="n-button-icon" /> {{ t('customer_service.days_and_hours', { hours, days }) }} GST
      </span>

      <span class="inline-flex items-center justify-center fw600 uppercase" n="sm">
        <NIcon icon="i-heroicons:shield-check" class="n-button-icon" /> {{ t('product.shipping_guarantee.label') }}
      </span>
    </div>
  </div>

  <NTinyCarousel :items="3" class="h10 bg-slate-900 text-white lg:hidden">
    <template #slide-0>
      <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
        <NIcon icon="i-heroicons:gift" class="n-button-icon" /> {{ t('incentives.gift_with_every_purchase.title') }}
      </span>
    </template>

    <template #slide-1>
      <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
        <NIcon icon="i-heroicons:clock" class="n-button-icon" /> {{ t('customer_service.days_and_hours', { hours, days }) }} GST
      </span>
    </template>

    <template #slide-2>
      <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
        <NIcon icon="i-heroicons:shield-check" class="n-button-icon" /> {{ t('product.shipping_guarantee.label') }}
      </span>
    </template>
  </NTinyCarousel>

  <Header ref="headerRef" :visible="isHeaderVisible">
    <template #logo>
      <div class="block -ml-4 lg:hidden">
        <NButton
          v-if="!isCheckoutPage"
          id="navigation"
          n="xl borderless"
          icon="i-heroicons:bars-3"
          class="p-0.25em children:m-0"
          :aria-label="$t('general.navigation.label')"
          @click="handleNavigationClick"
        />
      </div>

      <div
        class="my-auto ml0 mr-auto xs:ml2"
        :class="{ '-ml4': isCheckoutPage }"
      >
        <NuxtLinkLocale to="/" class="ml2 mr3 w18 flex overflow-hidden md:w-auto xs:w-[5.75rem]" :aria-label="$t('general.homepage.label')">
          <UzoLogo class="h8 w-auto text-slate-900 dark:text-white" />
        </NuxtLinkLocale>
      </div>

      <div v-if="!isCheckoutPage" class="mx2 hidden lg:inline-flex">
        <div class="h-full flex grow-1 flex-row items-center justify-start">
          <NuxtLinkLocale
            to="/collections/best-sellers"
            class="relative mx0 h10 w-auto flex shrink-0 cursor-pointer items-center justify-center whitespace-nowrap rounded-full px2.5 fw600 after:absolute after:inset-0 after:animate-[fade-out-scale-down_.2s_ease_both] after:rounded-full after:bg-slate-100/95 after:will-change-transform after:content-[''] after:-z-1 hover:after:animate-[fade-in-scale-up_.24s_linear_both]"
          >
            <NIcon icon="i-mdi:star-circle" class="mr1" />
            <span :title="t('pages.title.best_sellers')">{{ t('pages.title.best_sellers') }}</span>

            <span class="absolute rd-t-3xl rd-bl-md rd-br-3xl bg-orange-500 pb0.25 pl1.25 pr1.5 pt0.5 text-xs text-white fw-bold -right-2 -top-1.5">
              HOT
            </span>
          </NuxtLinkLocale>
        </div>

        <div class="h-full flex grow-1 flex-row items-center justify-start">
          <NuxtLinkLocale
            to="/collections/new-arrivals"
            class="relative mx0 h10 w-auto flex shrink-0 cursor-pointer items-center justify-center whitespace-nowrap rounded-full px2.5 fw600 after:absolute after:inset-0 after:animate-[fade-out-scale-down_.2s_ease_both] after:rounded-full after:bg-slate-100/95 after:will-change-transform after:content-[''] after:-z-1 hover:after:animate-[fade-in-scale-up_.24s_linear_both]"
          >
            <span :title="t('pages.title.new_arrivals')">{{ t('pages.title.new_arrivals') }}</span>
          </NuxtLinkLocale>
        </div>
      </div>
    </template>

    <template v-if="!isCheckoutPage" #center>
      <form class="mx4" @submit.prevent="submitSearch">
        <label for="searchbar" class="sr-only">{{ t('general.search.label') }}</label>
        <NTextInput id="searchbar" v-model="searchQueryInput" icon="i-heroicons:magnifying-glass" />
      </form>
    </template>

    <template #right>
      <div v-if="!isCheckoutPage" class="hidden sm:block">
        <LanguageButton />
      </div>
      <!--          <NButton n="xl" icon="i-heroicons:heart" class="p-0.25em children:m-0 border-0 shadow-none" /> -->
      <NButton
        id="support"
        :aria-label="t('general.support.label')"
        n="xl" icon="i-heroicons:question-mark-circle" class="border-0 p-0.25em shadow-none children:m-0" @click="openMessenger"
      />

      <NButton
        v-if="!isCheckoutPage"
        id="account"
        n="xl borderless"
        icon="i-heroicons:user"
        class="p0.25em children:m0 sm:hidden"
        to="/account"
        :aria-label="$t('page.account.title')"
      />

      <div v-if="!isCheckoutPage" class="hidden text-right sm:flex">
        <HMenu
          v-slot="{ open }"
          as="div"
          class="relative inline-block text-left"
          @mouseleave="onMouseLeave"
        >
          <div>
            <HMenuButton
              id="account-dropdown-menu"
              ref="accountMenuTrigger"
              class="n-button n-button-base active:n-button-active p0.25em focus-visible:n-focus-base n-transition children:m0 hover:n-button-hover n-disabled:n-disabled"
              n="xl borderless"
              :aria-label="$t('general.navigation.label')"
              @mouseover="onMouseOver"
              @click="router.push(localePath('/account'))"
            >
              <NIcon icon="i-heroicons:user" class="n-button-icon" />
            </HMenuButton>
          </div>

          <div v-if="open && loggedIn" @mouseover="onMouseOver">
            <Transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 op0"
              enter-to-class="transform scale-100 op100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 op100"
              leave-to-class="transform scale-95 op0"
            >
              <HMenuItems class="absolute right-0 mt2 w56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 divide-y divide-slate-100 focus:outline-none">
                <div v-for="accountNavItem in accountNav" :key="accountNavItem.label" class="p1">
                  <HMenuItem v-slot="{ close }" class="cursor-pointer" @click="router.push(localePath(accountNavItem.to))">
                    <div class="group w-full flex items-center rounded-md p2 text-sm text-slate-600 fw600 hover:bg-slate-50 hover:text-slate-900">
                      <NIcon :icon="accountNavItem.icon" class="n-button-icon" />
                      <NuxtLinkLocale
                        :to="accountNavItem.to"
                        @click="() => {
                          close()
                          accountNavItem.onClick?.()
                        }"
                      >
                        {{ accountNavItem.label }}
                      </NuxtLinkLocale>
                    </div>
                  </HMenuItem>
                </div>
              </HMenuItems>
            </Transition>
          </div>
        </HMenu>
      </div>

      <div class="relative flex items-center">
        <NButton
          id="cart"
          n="xl borderless"
          icon="i-heroicons:shopping-cart"
          class="p0.25em children:m0"
          :aria-label="$t('cart.label')"
          @click="handleCartClick"
        />

        <ClientOnly>
          <span
            v-if="activeOrder && activeOrder?.totalQuantity > 0"
            class="pointer-events-none absolute h6 w6 flex items-center justify-center rounded-full bg-orange-500 text-context -right-2 -top-1"
            n="sm white"
          >
            {{ activeOrder.totalQuantity }}
          </span>
        </ClientOnly>
      </div>
    </template>

    <template v-if="!isCheckoutPage" #navbar>
      <div class="flex content-center items-center lg:flex lg:px8 lg:py-2 lg:space-x-8">
        <NButton
          n="xl borderless"
          icon="i-heroicons:bars-3"
          class="p0.25em children:m-0"
          :aria-label="$t('general.navigation.label')"
          @click="handleNavigationClick"
        />

        <template v-if="rootCollections?.length">
          <NuxtLinkLocale
            v-for="collection in rootCollections.slice(0, 9)"
            :key="collection.id"
            :to="`/collections/${collection.id}/${collection.slug}`"
            n="sm slate9 dark:slate2"
            class="whitespace-nowrap text-context fw600 n-transition"
          >
            {{ collection.name }}
          </NuxtLinkLocale>
        </template>
        <template v-else>
          <NSkeleton v-for="i in 9" :key="i" :style="{ width: `${100 + (i * i + i) % 7 * 20}px` }" pulse />
        </template>
      </div>
    </template>

    <template v-if="!isCheckoutPage" #search>
      <div class="w-full">
        <form @submit.prevent="submitSearch">
          <label for="search" class="sr-only">{{ $t('general.search.label') }}</label>
          <NTextInput id="search" v-model="searchQueryInput" icon="i-heroicons:magnifying-glass" />
        </form>
      </div>
    </template>
  </Header>

  <slot />

  <LazyNavDrawer />
  <LazyCartDrawer />
  <LazyMessenger v-if="showMessenger || messengerVisible" n="orange5" />

  <div class="flex-1" />

  <ContentGuard>
    <div v-if="!isCheckoutPage" class="mb6 bg-slate-50">
      <div class="mx-auto max-w-[88rem] py8 lg:px4 sm:px2 sm:py14">
        <IllustratedIncentives class="grid grid-cols-1 mx-auto max-w-2xl gap-x-8 gap-y-12 px4 lg:grid-cols-2 lg:max-w-none lg:gap-y-16" />
      </div>
    </div>
  </ContentGuard>

  <div class="mx-auto max-w-7xl p6">
    <NCard class="flex flex-col items-center justify-between p-4 sm:flex-row">
      <!-- Icon and Text -->
      <div class="mb-4 flex items-center sm:mb-0">
        <!-- Chat Icon -->
        <div class="relative mr-3 h-8 w-8">
          <NIcon icon="i-heroicons:chat-bubble-left-ellipsis" n="3xl" />
          <span class="absolute top-0 h-3 w-3 flex -right-1">
            <span class="absolute h-full w-full inline-flex animate-ping rounded-full bg-emerald-400 opacity-75" />
            <span class="relative h-3 w-3 inline-flex rounded-full bg-emerald-500" />
          </span>
        </div>

        <div class="sm:ml4">
          <div class="flex items-center">
            <h4 class="text-lg font-semibold">
              {{ t('support_agent.need_assistance.label') }}
            </h4>
          </div>
          <!-- Subtitle -->
          <p class="text-sm text-slate-600 leading-6">
            {{ t('support_agent.hours_of_operation.label') }}
          </p>
        </div>
      </div>

      <!-- Start Chat Button -->
      <NButton id="open-messenger" n="orange5 solid base" class="rounded-full" @click="openMessenger">
        {{ t('support_agent.start_chat.label') }}
      </NButton>
    </NCard>
  </div>

  <!-- App Banner -->
  <AppPromotionBanner />

  <!-- Footer -->
  <LayoutFooter />
</template>
