<script setup lang="ts">
import { upperFirst } from 'scule'
import type { UnwrapRef } from 'vue'

const { t, locale, locales, setLocale } = useI18n()

const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => locales.value.map((l: any) => {
  const formattedNumber = new Intl.NumberFormat(l.iso, {
    style: 'currency',
    currency: l.currency,
  }).format(0)

  const localCurrencySymbol = formattedNumber.replace(/[\d.,\s]/g, '')

  return {
    ...l,
    label: `${l.country} ` + `(${localCurrencySymbol})`,
    nativeName: upperFirst(l.nativeName),
  }
}))

const selectedLocale = ref(locale.value)
const localeFlagMap: Record<UnwrapRef<typeof selectedLocale>, string> = {
  en: 'i-circle-flags:us',
  cs: 'i-circle-flags:cz',
  sk: 'i-circle-flags:sk',
  hu: 'i-circle-flags:hu',
  pl: 'i-circle-flags:pl',
  ro: 'i-circle-flags:ro',
}

async function handleClick(locale: string) {
  await setLocale(locale)
  switchLocalePath(locale)
  window.location.reload()
}
</script>

<template>
  <HMenu as="div" class="relative inline-block">
    <div>
      <HMenuButton
        id="language-button"
        class="n-button n-button-base whitespace-nowrap active:n-button-active p0.25em focus-visible:n-focus-base n-transition children:m0 hover:n-button-hover n-disabled:n-disabled"
        n="borderless"
        :aria-label="t('general.language_and_currency.label')"
      >
        <NIcon :icon="localeFlagMap[selectedLocale]" alt="flag" class="n-icon-button mr2 n-icon" />
        {{ availableLocales.find((l) => l.code === selectedLocale)?.label }}
      </HMenuButton>
    </div>

    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 op0"
      enter-to-class="transform scale-100 op100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 op100"
      leave-to-class="transform scale-95 op0"
    >
      <HMenuItems
        class="absolute right-0 mt2 w40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 divide-y divide-slate-100 focus:outline-none"
        :side-offset="5"
      >
        <div v-for="loc in availableLocales" :key="loc.code" class="p1">
          <HMenuItem class="cursor-pointer" @click="handleClick(loc.code)">
            <div class="group w-full flex items-center rounded-md p2 text-sm text-slate-600 fw600 hover:bg-slate-50 hover:text-slate-900">
              <NIcon :icon="localeFlagMap[loc.code]" alt="flag" class="n-icon-button mr2 n-icon" />
              {{ loc.label }}
            </div>
          </HMenuItem>
        </div>
      </HMenuItems>
    </Transition>
  </HMenu>
</template>
